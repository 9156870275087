import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ParallaxTile from '../../Controls/ParallaxTile/ParallaxTile';

import styles from './Reviews.module.css';

const Reviews = ({ widgetContainerId }) => {
  useEffect(
    () => {
      const script = document.createElement('script');

      script.src = 'https://apps.elfsight.com/p/platform.js';
      script.async = true;
      script.defer = true;

      const parentDiv = document.getElementById(widgetContainerId);
      parentDiv.appendChild(script);
      console.log('Just appended script', { parentDiv });
      // document.body.appendChild(script);
    },
    []);

  return (
    <ParallaxTile outerClassName={styles.outerContainer} innerClassName={styles.innerContainer}>
      <div className={styles.contentContainer} id={widgetContainerId}>
        <header>What Our Customers Say</header>

        <div className="elfsight-app-2350875f-e366-4052-8df9-4e079c9a31cf"></div>

      </div>
    </ParallaxTile>
  );
};

Reviews.propTypes = {
  widgetContainerId: PropTypes.string,
};

Reviews.defaultProps = {
  widgetContainerId: 'iJ0S9JxFbyPDCvMDDdsu9iMi2T3Yscvve5awEWgbqYVvBAmHJY'
};

export default Reviews;
