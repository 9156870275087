import React from 'react';

import PortalScreenImg from '../../../img/SiteLink-Portal-Screen.jpg';
import ContentWrapper from '../../Controls/ContentWrapper/ContentWrapper';
import ShadowCard from '../../Controls/ShadowCard/ShadowCard';

import styles from './PayOnline.module.css';

const PayOnline = () => {
  const doPayment = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.open('https://www.smdservers.net/SLWebSiteTemplate_V2/login.aspx?sCorpCode=CBQL&sLocationCode=L001', '_blank');
  };

  return (
    <ContentWrapper className={styles.outerContainer}>
      <ShadowCard className={styles.contentContainer}>
        <div className={styles.headerContainer}>Online Payments by Storable</div>

        <div className={styles.imgContainer}>
          <img src={PortalScreenImg} alt="Redirect to Sitelink" />
        </div>

        <div className={styles.descContainer}>
          <p>
            Online payments are carried out using a web portal that is operated
            by <a href="https://www.storable.com/products/sitelink/">Storable</a>,
            the maker of SiteLink software, which we use for managing
            our storage operations.
          </p>
          <p>
            Clicking the button below will open this portal in a new tab, which
            is on a different site from shockeyministorage.com.
          </p>
          <p className={styles.important}>
            - Important -
          </p>
          <p>
            When setting up your online payment account for the first time, you will be asked to
            specify your unit.
          </p>
          <p>
            If you have any questions, concerns, or difficulities, please feel
            free to call the office during business hours
            at <a href="tel:7242870024">724-287-0024</a>.
          </p>
        </div>

        <div className={styles.redirectContainer}>
          <button onClick={doPayment}>Go To Storable Payment Portal &rarr;</button>
        </div>
      </ShadowCard>
    </ContentWrapper>
  );
};

export default PayOnline;
