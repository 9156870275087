import React from 'react';

import styles from './FAQ.module.css';

import ShadowCard from '../../Controls/ShadowCard/ShadowCard';

import { questionsAndAnswers } from '../../../site-data/faq-questions-answers.data';
import ContentWrapper from '../../Controls/ContentWrapper/ContentWrapper';

const FAQ = () => (
  <ContentWrapper className={styles.outerContainer}>
    {
      questionsAndAnswers.map(qa => (
        <ShadowCard className={styles.questionAndAnswerContainer} key={qa.key}>
          <div className={styles.questionContainer}>{ qa.q }</div>
          <div className={styles.answerContainer}>{ qa.a }</div>
        </ShadowCard>
      ))
    }
  </ContentWrapper>
);

export default FAQ;
