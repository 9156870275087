import React from 'react';

import ContentWrapper from '../../Controls/ContentWrapper/ContentWrapper';
import ShadowCard from '../../Controls/ShadowCard/ShadowCard';

import styles from './AboutUs.module.css';

const AboutUs = () => (
  <ContentWrapper className={styles.outerContainer}>
    <ShadowCard>
      <div className={styles.header}>
        About Shockey Mini Storage
      </div>
      <div className={styles.desc}>
        <p>
          Shockey Mini Storage was founded in 1981 by George and Brenda Shockey.
        </p>
        <p>
          It was the first self-storage facility in Butler Township, and the second
          commercial operation from the Shockey Family, who had founded the Shockey
          Motel in 1959, which was frequented by business travelers for Armco.
        </p>
        <p>
          Beginning with two buildings, Shockey Mini Storage steadily expanded to five
          buildings, and has been working hard to meet the needs of people in Butler
          for more than forty years - and counting.
        </p>
        <p>
          Shockey Mini Storage is proud to be a family-owned business.
        </p>
      </div>
    </ShadowCard>

  </ContentWrapper>
);

export default AboutUs;
