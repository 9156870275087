import FiveByFive from '../img/Unit-Sizes/Five-by-Five.png';
import FiveByTen from '../img/Unit-Sizes/Five-by-Ten.png';
import TenByTen from '../img/Unit-Sizes/Ten-by-Ten.png';
import TenByFifteen from '../img/Unit-Sizes/Ten-by-Fifteen.png';
import TenByTwenty from '../img/Unit-Sizes/Ten-by-Twenty.png';
import TenByTwentyFive from '../img/Unit-Sizes/Ten-by-Twenty-Five.png';
import TenByThirty from '../img/Unit-Sizes/Ten-by-Thirty.png';
import TenByForty from '../img/Unit-Sizes/Ten-By-Forty.png'; // eslint-disable-line import/no-duplicates
import TenByFifty from '../img/Unit-Sizes/Ten-By-Forty.png'; // eslint-disable-line import/no-duplicates

export const unitSizesAndDescs = [
  {
    size: '5\' x 5\', 25 sq. ft.',
    img: FiveByFive,
    unitDesc: <>
      <p>
        This unit is about the size of a regular closet. The space is perfect for about
        a dozen boxes, or a small set of furnishings like a desk and chair. You can also
        store a variety of other belongings like a bicycle, golf clubs, or a filing cabinet.
      </p>
    </>,
    prices: [
      {
        price: 49,
        desc: '8\' Tall, Drive-Up or Walk-In Hallway'
      },
    ],
  },
  {
    size: '5\' x 10\', 50 sq. ft.',
    img: FiveByTen,
    unitDesc: <>
      <p>
        It&apos;s like a walk-in closet. This unit can hold several boxes. Most home furnishings - including
        larger items like a bed, mattress and box spring - can fit into a unit this size.
      </p>
    </>,
    prices: [
      {
        price: 70,
        desc: '8\' Tall, Drive-Up or Walk-In Hallway'
      },
      {
        price: 76,
        desc: '11\' Tall, Drive-Up'
      }
    ]
  },
  {
    size: '10\' x 10\', 100 sq. ft.',
    img: TenByTen,
    unitDesc: <>
    <p>
      This space can generally hold the contents of a couple rooms. You could fit an entire bedroom set,
      a couch, and a kitchen table.  You can also store many larger items like kitchen and laundry
      appliances. It&apos;s not large enough for a car, but you can store a couple of motorcycles.
    </p>
  </>,
    prices: [
      {
        price: 107,
        desc: '8\' Tall, Drive-Up or Walk-In Hallway'
      },
      {
        price: 109,
        desc: '11\' Tall, Drive-Up'
      }
    ]
  },
  {
    size: '10\' x 15\', 150 sq. ft.',
    img: TenByFifteen,
    unitDesc: <>
      <p>
        You can use a space like this to store the contents of a small house or a
        two-bedroom apartment: two couches, two mattress sets, a coffee table,
        fridge, and TV.
      </p>
    </>,
    prices: [
      {
        price: 121,
        desc: '8\' Tall, Drive-Up'
      }
    ]
  },
  {
    size: '10\' x 20\', 200 sq. ft.',
    img: TenByTwenty,
    unitDesc: <>
      <p>
        The size of a one-car garage, this space can hold a fridge, washer and dryer, three
        mattress sets, a dining room set, bookcases, couches, and boxes.
      </p>
    </>,
    prices: [
      {
        price: 149,
        desc: '8\' Tall, Drive-Up'
      },
      {
        price: 156,
        desc: '11\' Tall, Drive-Up'
      }
    ]
  },
  {
    size: '10\' x 25\', 250 sq. ft.',
    img: TenByTwentyFive,
    unitDesc: <>
      <p>
        This space can fit a couple of couches, an oversized arm chair, dining room set,
        fridge, washer and dryer, entertainment nook, bookcases, and more.
      </p>
    </>,
    prices: [
      {
        price: 176,
        desc: '8\' Tall, Drive-Up'
      },
      {
        price: 188,
        desc: '11\' Tall, Drive-Up'
      }
    ]
  },
  {
    size: '10\' x 30\', 300 sq. ft.',
    img: TenByThirty,
    unitDesc: <>
      <p>
        You can generally fit the contents of a 3-4 bedroom house. This space holds many mattress sets,
        dining room sets, couches, and boxes.
      </p>
    </>,
    prices: [
      {
        price: 199,
        desc: '8\' Tall, Drive-Up, Single Door'
      },
      {
        price: 209,
        desc: '8\' Tall, Drive-Up, Dual Doors'
      },
      {
        price: 211,
        desc: '11\' Tall, Drive-Up'
      }
    ]
  },
  {
    size: '10\' x 40\', 400 sq. ft.',
    img: TenByForty,
    prices: [
      {
        price: 251,
        desc: '8\' Tall, Drive-Up, Dual Doors'
      },
      {
        price: 257,
        desc: '11\' Tall, Drive-Up'
      }
    ]
  },
  {
    size: '10\' x 50\', 500 sq. ft.',
    img: TenByFifty,
    prices: [
      {
        price: 305,
        desc: '11\' Tall, Drive-Up, Dual Doors'
      }
    ]
  }
];
