import React from 'react';
import PropTypes from 'prop-types';

import styles from './ParallaxTile.module.css';

const ParallaxTile = ({ children, outerClassName, innerClassName }) => {
  const outerPanelClasses = [
    styles.panelContainer,
    outerClassName,
  ].filter(c => c?.length > 0);

  const contentPanelClasses = [
    styles.featuredContentContainer,
    innerClassName,
  ].filter(c => c?.length > 0);

  return (
    <div className={outerPanelClasses.join(' ')}>
      <div className={contentPanelClasses.join(' ')}>
        { children }
      </div>
    </div>
  );
};

ParallaxTile.propTypes = {
  children: PropTypes.element.isRequired,
  outerClassName: PropTypes.string,
  innerClassName: PropTypes.string,
};

ParallaxTile.defaultProps = {
  outerClassName: null,
  innerClassName: null,
};

export default ParallaxTile;
