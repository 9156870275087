import React from 'react';

import styles from './SubmittedPanel.module.css';

const SubmittedPanel = () => (
  <div className={styles.contentContainer}>
    <p>
      Thank you for your submission!
    </p>
    <p>
      We read and respond to all form submissions, typically within one business day.
    </p>
    <p>
      If you have any questions, please feel free to call our office at <a href="tel:7242870024">724-287-0024</a>.
    </p>
  </div>
);

export default SubmittedPanel;
