import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
  IconArchive, IconCreditCard, IconDimensions,
  IconHome, IconPhoneCall, IconQuestionMark,
  IconSend, IconMenu2
} from '@tabler/icons';

import styles from './NavBar.module.css';
import NavItem from '../NavItem/NavItem';

const NavBar = ({ scrollCallbackFn }) => {
  // console.log('Value of scroll', { scrollTop: document.documentElement.scrollTop });
  const runOnScroll = (scrollTop) => {
    // console.log('Scroll!', { e, scrollTop })
    const classes = document.getElementById('smsNav').className.split(' ');

    if (scrollTop > 20) {
      if (!classes.includes(styles.scrolled)) {
        classes.push(styles.scrolled);
        document.getElementById('smsNav').className = classes.join(' ');

        scrollCallbackFn(true);
      }
    } else if (classes.includes(styles.scrolled)) {
      document.getElementById('smsNav').className = classes.filter(c => c !== styles.scrolled).join(' ');

      scrollCallbackFn(false);
    }
  };

  useEffect(
    () => {
      window.addEventListener(
        'scroll',
        (e) => runOnScroll(e.srcElement?.scrollingElement?.scrollTop || 0),
        { passive: true }
      );

      setTimeout(
        () => {
          runOnScroll(document?.documentElement?.scrollTop || 0);
        },
        1000,
      );
    },
    [],
  );

  const [ isOpen, setIsOpen ] = useState(false);

  const navBarClasses = [
    styles.navBarContainer,
    isOpen ? styles.open : null
  ].filter(c => c?.length > 0);

  const location = useLocation();
  const pathname = location.pathname.toLowerCase();

  console.log('Value of location', location);

  return (
    <div className={navBarClasses.join(' ')} id="smsNav">
      <div className={styles.nonNavContainer}>
        <div className={styles.logoContainer} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.location.href = '/';
        }}></div>
        <div className={styles.spacer}></div>
        <div className={styles.burgerContainer} onClick={() => setIsOpen(!isOpen)}>
          <IconMenu2
            size={50} // set custom `width` and `height`
            color="white" // set `stroke` color
            stroke={1} // set `stroke-width`
            strokeLinejoin="miter" // override other SVG props
          />
        </div>
      </div>

      <div className={styles.navItemsContainer}>
        <NavItem Icon={IconHome} href='/' label='Home' isActive={pathname === '/'} />
        <NavItem Icon={IconDimensions} href='/sizes-and-prices' label='Sizes & Prices' isActive={pathname === '/sizes-and-prices'} />
        <NavItem Icon={IconQuestionMark} href='/faq' label='FAQ' isActive={pathname === '/faq'} />
        <NavItem Icon={IconCreditCard} href='/pay-online' label='Pay Online' isActive={pathname === '/pay-online'} />
        <NavItem Icon={IconSend} href='/contact-us' label='Contact Us' isActive={pathname === '/contact-us'} />
        <NavItem Icon={IconArchive} href='/about-us' label='About Us' isActive={pathname === '/about-us'} />
        <NavItem Icon={IconPhoneCall} href='tel:7242870024' label='Call 724-287-0024' className={styles.callContainer} />
      </div>
    </div>
  );
};

NavBar.propTypes = {
  scrollCallbackFn: PropTypes.func.isRequired,
};

export default NavBar;
