import React from 'react';
import { useDocumentTitle } from '../../../utils/shared-utils';
import { unitSizesAndDescs } from '../../../site-data/unit-sizes-and-desc.data';

import styles from './SizesAndPrices.module.css';
import ShadowCard from '../../Controls/ShadowCard/ShadowCard';
import ContentWrapper from '../../Controls/ContentWrapper/ContentWrapper';

const SizesAndPrices = () => {
  useDocumentTitle('Sizes & Prices - Shockey Mini Storage');

  return (
    <ContentWrapper className={styles.outerContainer}>
      <ShadowCard className={styles.pageHeaderContainer}>
        <span className={styles.header}>Sizes and Prices</span>
        <div className={styles.desc}>
          <p>
            Our facility has storage units in five different buildings in sizes ranging from 5&apos; x 5&apos; to
            10&apos; x 50&apos;. Four of these buildings are eight feet tall, and the other is eleven feet tall.
          </p>
        </div>
      </ShadowCard>

    {
      unitSizesAndDescs.map(d => {
        const key = d.size
          .split(',')[0]
          .replaceAll(' ', '')
          .replaceAll('\'', '');

        return (
          <ShadowCard className={styles.sizeDetailsContainer} key={key}>
            <div className={styles.sizeImagesContainer}>
              {
                d.img &&
                  <div className={styles.sizeImages}><img src={d.img} alt="5 x 5 Unit" style={{ height: '175px' }} /></div>
            }
            </div>

            <div className={styles.pricesOuterContainer}>
              <div className={styles.sizeHeader}>{ d.size }</div>

              {
                d.prices.map(p => (
                  <div className={styles.priceAndDescContainer} key={`${key}_${p.price}`}>
                    <div className={styles.descContainer}>{ p.desc }</div>
                    <div className={styles.dotLeader}></div>
                    <div className={styles.priceContainer}>${p.price} / mo.</div>
                  </div>
                ))
              }

              {
                d.unitDesc && (<>
                  <div className={styles.spacer}></div>
                  <div className={styles.unitUsesContainer}>
                    { d.unitDesc }
                </div>
                </>)
              }
            </div>
          </ShadowCard>
        );
      })
    }

    </ContentWrapper>
  );
};

export default SizesAndPrices;
