import React, { useState } from 'react';

import styles from './ContactUs.module.css';
import ShadowCard from '../../Controls/ShadowCard/ShadowCard';
import ContentWrapper from '../../Controls/ContentWrapper/ContentWrapper';
import FormPanel from './Panels/FormPanel/FormPanel';
import SubmittedPanel from './Panels/SubmittedPanel/SubmittedPanel';

const ContactUs = () => {
  const [ hasSubmitted, setHasSubmitted ] = useState(false);

  return (
    <ContentWrapper className={styles.outerContainer}>
      <ShadowCard className={styles.contentContainer}>
        {
          !hasSubmitted
            ? <FormPanel submitCompletedFn={() => setHasSubmitted(true)} />
            : <SubmittedPanel />
        }
      </ShadowCard>
    </ContentWrapper>
  );
};

export default ContactUs;
