import React from 'react';
import { useDocumentTitle } from '../../../utils/shared-utils';
import ContactUs from '../../Tiles/ContactUs/ContactUs';
import Features from '../../Tiles/Features/Features';
import OFYLK from '../../Tiles/OFYLK/OFYLK';
import Reviews from '../../Tiles/Reviews/Reviews';

const Home = () => {
  useDocumentTitle('Home - Shockey Mini Storage');

  return (
  <>
    <OFYLK />
    <Features />
    <Reviews />
    <ContactUs />
  </>
  );
};

export default Home;
