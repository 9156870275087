import React from 'react';
import ParallaxTile from '../../Controls/ParallaxTile/ParallaxTile';

import { IconBuildingWarehouse, IconLock } from '@tabler/icons';

import styles from './OFYLK.module.css';

const OFYLK = () => (
  <ParallaxTile outerClassName={styles.outerContainer} innerClassName={styles.innerContainer}>
    <div className={styles.messageContainer}>
      <div className={styles.lineContainer}>
        <div>
          <IconBuildingWarehouse
            size={60} // set custom `width` and `height`
            color="black" // set `stroke` color
            stroke={2} // set `stroke-width`
            strokeLinejoin="miter" // override other SVG props
          />
        </div>
        <div>
          Our Facility.
        </div>
      </div>
      <div className={styles.lineContainer}>
        <div>
        <IconLock
            size={60} // set custom `width` and `height`
            color="black" // set `stroke` color
            stroke={2} // set `stroke-width`
            strokeLinejoin="miter" // override other SVG props
          />
        </div>
        <div>
          Your Lock &amp; Key.
        </div>
      </div>
    </div>
  </ParallaxTile>
);

export default OFYLK;
