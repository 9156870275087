import React from 'react';
import PropTypes from 'prop-types';

import styles from './ShadowCard.module.css';

const ShadowCard = ({ className, children }) => {
  const classes = [
    className,
    styles.cardContainer
  ].filter(c => !!c);

  return (
    <div className={classes.join(' ')}>
      { children }
    </div>
  );
};

ShadowCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

ShadowCard.defaultProps = {
  className: null,
};

export default ShadowCard;
