import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar/NavBar';
import styles from './OuterLayout.module.css';

const OuterLayout = ({ children }) => {
  const setScrollState = (scrollState) => {
    const el = document.getElementById('outerContentContainer');
    const classes = el.className.split(' ');

    if (scrollState) {
      if (!classes.includes(styles.scrolled)) {
        classes.push(styles.scrolled);
        el.className = classes.join(' ');
      }
    } else if (classes.includes(styles.scrolled)) {
      el.className = classes.filter(c => c !== styles.scrolled).join(' ');
    }
  };

  return (
    <div className={styles.outerContainer} id="outerContentContainer">
      <NavBar scrollCallbackFn={setScrollState} />
      <div className={styles.contentContainer}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

OuterLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default OuterLayout;
