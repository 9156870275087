import React from 'react';
import PropTypes from 'prop-types';

import styles from './NavItem.module.css';

const NavItem = ({ Icon, href, label, className, isActive }) => {
  const classes = [
    styles.outerContainer,
    className,
    isActive ? styles.active : null,
  ].filter(c => c?.length > 0);

  return (
    <nav className={classes.join(' ')} onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();

      window.location.href = href;
    }}>
      <Icon
        size={20} // set custom `width` and `height`
        color="white" // set `stroke` color
        stroke={1} // set `stroke-width`
        strokeLinejoin="miter" // override other SVG props
      />
      <a href={href}>{ label }</a>
    </nav>
  );
};

NavItem.propTypes = {
  Icon: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

NavItem.defaultProps = {
  className: null,
  isActive: false,
};

export default NavItem;
