import React, { useEffect } from 'react';

import ParallaxTile from '../../Controls/ParallaxTile/ParallaxTile';

import styles from './ContactUs.module.css';

const ContactUs = () => {
  useEffect(
    () => {
      const iframe = document.createElement('iframe');

      iframe.src = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.2520310094287!2d-79.92549458446366!3d40.86634293609555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88337d33bf3fbb17%3A0xa8f0cee6d198247d!2sShockey%20Mini%20Storage!5e0!3m2!1sen!2sus!4v1658457851010!5m2!1sen!2sus';
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.style = 'border:0;';
      iframe.allowFullscreen = false;
      iframe.loading = 'lazy';
      iframe.referrerPolicy = 'no-referrer-when-downgrade';

      const parentDiv = document.getElementById('smsmap');
      parentDiv.appendChild(iframe);
      console.log('Just appended iframe', { parentDiv });
      // document.body.appendChild(script);
    },
    []);

  return (
    <ParallaxTile outerClassName={styles.outerContainer} innerClassName={styles.innerContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.mapContainer} id="smsmap">
        </div>
        <div className={styles.locationDescContainer}>
          <span className={styles.header}>Where We Are</span>
          <p>
            We are at the top of New Castle Hill in Butler, PA, near the Butler High School.
          </p>
          <p>
            We are situated right behind Villa Grande restaurant, and across the street from Skate Castle,
            at the following address:
          </p>
          <p className={styles.address}>
            140 Shockey Lane<br />
            Butler, PA 16001
          </p>
          <p>Use the map to look around and better understand where we are and what&apos;s around us.</p>
        </div>
      </div>
    </ParallaxTile>
  );
};

export default ContactUs;
