import React from 'react';
import ParallaxTile from '../../Controls/ParallaxTile/ParallaxTile';

import styles from './Features.module.css';
import { IconAlarm, IconMap2, IconMoon, IconTruck, IconUser } from '@tabler/icons';

const Features = () => (
  <ParallaxTile outerClassName={styles.outerContainer} innerClassName={styles.innerContainer}>
    <div className={styles.messageContainer}>
      <div className={styles.featuresHeader}>Why Store With Us?</div>

      <div className={styles.reasonsContainer}>
        <div className={styles.itemSymbol}>
          <IconUser
            size={30} // set custom `width` and `height`
            color="white" // set `stroke` color
            stroke={3} // set `stroke-width`
            strokeLinejoin="miter" // override other SVG props
          />
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.itemHeader}>
            Onsite Office &amp; Manager
          </span>
          <div className={styles.itemDesc}>
            <span>Every weekday, from 9 am to 4 pm.</span>
            <span>Call <a href="tel:7242870024">724-287-0024</a>.</span>
          </div>
        </div>

        <div className={styles.itemSymbol}>
          <IconAlarm
              size={30} // set custom `width` and `height`
              color="white" // set `stroke` color
              stroke={3} // set `stroke-width`
              strokeLinejoin="miter" // override other SVG props
            />
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.itemHeader}>
            No Long Term Committment
          </span>
          <div className={styles.itemDesc}>
            Store Your Belongings On a Month-to-Month Basis!
          </div>
        </div>

        <div className={styles.itemSymbol}>
          <IconMoon
                size={30} // set custom `width` and `height`
                color="white" // set `stroke` color
                stroke={3} // set `stroke-width`
                strokeLinejoin="miter" // override other SVG props
              />
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.itemHeader}>
            Unit Access 24 / 7 / 365
          </span>
          <div className={styles.itemDesc}>
            Access your unit on your schedule!
          </div>
        </div>

        <div className={styles.itemSymbol}>
          <IconMap2
                size={30} // set custom `width` and `height`
                color="white" // set `stroke` color
                stroke={3} // set `stroke-width`
                strokeLinejoin="miter" // override other SVG props
              />
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.itemHeader}>
            Convenient Location
          </span>
          <div className={styles.itemDesc}>
            Right at the top of New Castle Hill in Butler!
          </div>
        </div>

        <div className={styles.itemSymbol}>
          <IconTruck
                size={30} // set custom `width` and `height`
                color="white" // set `stroke` color
                stroke={3} // set `stroke-width`
                strokeLinejoin="miter" // override other SVG props
              />
        </div>
        <div className={styles.itemInfo}>
          <span className={styles.itemHeader}>
            Wide Lanes &amp; Driveways
          </span>
          <div className={styles.itemDesc}>
            Big Truck or Trailer? No Problem.
          </div>
        </div>
      </div>
    </div>
  </ParallaxTile>
);

export default Features;
