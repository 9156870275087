export const questionsAndAnswers = [
  {
    key: 'where',
    q: 'Where is Shockey Mini Storage located?',
    a: (<>
      <p>Shockey Mini Storage is at the top of New Castle Hill in Butler, PA. Our address is:</p>
      <p>
        140 Shockey Lane<br />
        Butler, PA 16001
      </p>
    </>)
  },
  {
    key: 'officeHours',
    q: 'What are the office hours?',
    a: (<>
      <p>Monday - Friday: 9 AM to 4 PM</p>
    </>)
  },
  {
    key: 'when',
    q: 'When can I access my storage unit?',
    a: (<>
      <p>Whenever you want! Access your belongings 24/7.</p>
    </>)
  },
  {
    key: 'longTerm',
    q: 'Am I required to store long-term?',
    a: (<>
      <p>Nope. We lease on a month-to-month basis.</p>
    </>)
  },
  {
    key: 'rentDue',
    q: 'When is my rent due?',
    a: (<>
      <p>
        Rent payments are due on the first of each month. We allow a 10-day grace
        period before late fees apply. If your payment is not received by close of
        business on the 10th of the month, you will incur a late fee.
      </p>
    </>)
  },
  {
    key: 'documentation',
    q: 'What documentation do I need to rent a unit?',
    a: (<>
      <p>
        You need one of the following valid forms of government-issued identification:
      </p>
      <ul>
        <li>Driver&apos;s License</li>
        <li>Passport</li>
        <li>State-Issued ID</li>
      </ul>
    </>)
  },
  {
    key: 'howPay',
    q: 'How do I pay my bill?',
    a: (<>
      <p>
        You can <a href="/pay-online">pay your bill online</a> using a credit card, debit card, or e-Check.
      </p>
      <p>
        You can give us a call, at <a href="tel:7242870024">724-287-0024</a>, and we can process your payment over the phone.
      </p>
      <p>
        We also accept mailed-in checks and money orders.  The mailing address is:
      </p>
      <p style={{ paddingLeft: '2em' }}>
        140 Shockey Lane<br />
        Butler, PA 16001
      </p>
      <p>
        We also have a payment drop slot in our office door, where you can leave your payment in the form
        of a check, money order, or cash. Just make sure to put the payment in an envelope and mark who
        the payment is for.
      </p>
    </>)
  },
  {
    key: 'notStore',
    q: 'What items am I not allowed to store?',
    a: (<>
        <p>Contact us if you have any questions about a specific item. The following, among others, are prohibited:</p>
        <ul>
          <li>Anything Flammable or Hazardous</li>
          <li>Perishables</li>
          <li>Plants</li>
          <li>Animals</li>
          <li>Stolen or Illegal Goods</li>
        </ul>
    </>)
  },
  {
    key: 'moveOut',
    q: 'I don\'t need my unit anymore - what do I do now?',
    a: (<>
      <p>
        We ask our tenants to give us at least 24-hour notice when vacating a unit.
        You must clean out the unit completely and sweep the floor. Once complete,
        we will return your security deposit.
      </p>
    </>)
  }
];
