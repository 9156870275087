import React, { Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FormspreeProvider } from '@formspree/react';

import OuterLayout from './components/Layout/OuterLayout/OuterLayout';
import AboutUs from './components/Pages/AboutUs/AboutUs';
import ContactUs from './components/Pages/ContactUs/ContactUs';
import FAQ from './components/Pages/FAQ/FAQ';
import Home from './components/Pages/Home/Home';
import PayOnline from './components/Pages/PayOnline/PayOnline';
import SizesAndPrices from './components/Pages/SizesAndPrices/SizesAndPrices';

import './custom.css';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <FormspreeProvider project="1996983837510335904">
        <OuterLayout>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/sizes-and-prices" element={<SizesAndPrices />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/pay-online" element={<PayOnline />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />

            <Route
              path="/140-shockey-ln-butler-pa-16001"
              element={<Navigate replace to="/" />}
            />
            <Route
              path="/size-guide"
              element={<Navigate replace to="/sizes-and-prices" />}
            />
            <Route path="/nearby" element={<Navigate replace to="/" />} />
            <Route
              path="/payonline"
              element={<Navigate replace to="/pay-online" />}
            />
          </Routes>
        </OuterLayout>
      </FormspreeProvider>
    );
  }
}
