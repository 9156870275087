import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './FormPanel.module.css';

import { useForm, ValidationError } from '@formspree/react';

const FormPanel = ({ submitCompletedFn }) => {
  const [ state, handleSubmit ] = useForm('contactForm');
  const [ hasSucceeded, setHasSucceeded ] = useState(false);

  useEffect(
    () => {
      console.log('inside effect', state);
      if (state.succeeded && !hasSucceeded) {
        setHasSucceeded(true);
        submitCompletedFn();
      }
    },
    [ state ]
  );

  return (
    <>
      <div className={styles.headerAndDirectionsContainer}>
        <div className={styles.headerContainer}>Contact Us</div>
        <div>
          <p>
            Please fill out the form below and submit. We respond to all inquiries, typically within
            one business day.
          </p>
          <p>
            If you need to speak to someone, you may call our office at <a href="tel:7242870024">724-287-0024</a> during
            business hours.
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className={styles.formContainer}>
          <div className={styles.labelContainer}>
            <label htmlFor="firstname">
              First Name:
            </label>
          </div>
          <div className={styles.inputContainer}>
            <input
              id="firstname"
              type="text"
              name="firstname"
            />
            <ValidationError
              prefix="First Name"
              field="firstname"
              errors={state.errors}
            />
          </div>

          <div className={styles.labelContainer}>
            <label htmlFor="lastname">
              Last Name:
            </label>
          </div>
          <div className={styles.inputContainer}>
            <input
              id="lastname"
              type="text"
              name="lastname"
            />
            <ValidationError
              prefix="Last Name"
              field="lastname"
              errors={state.errors}
            />
          </div>

          <div className={styles.labelContainer}>
            <label htmlFor="email">
              Email:
            </label>
          </div>
          <div className={styles.inputContainer}>
            <input
              id="email"
              type="email"
              name="email"
              className={styles.emailInput}
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </div>

          <div className={styles.labelContainer}>
            <label htmlFor="phonenumber">
              Phone Number:
            </label>
          </div>
          <div className={styles.inputContainer}>
            <input
              id="phonenumber"
              type="phonenumber"
              name="phonenumber"
            />
            <ValidationError
              prefix="Phone Number"
              field="phonenumber"
              errors={state.errors}
            />
          </div>

          <div className={`${styles.labelContainer} ${styles.fullRow}`}>
            <label htmlFor="message">
              Message:
            </label>
          </div>
          <div className={`${styles.inputContainer} ${styles.fullRow}`}>
            <textarea
              id="message"
              name="message"
              cols="60"
              rows="10"
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>

          <div className={`${styles.submitContainer} ${styles.fullRow}`}>
            <button type="submit" disabled={state.submitting}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

FormPanel.propTypes = {
  submitCompletedFn: PropTypes.func.isRequired,
};

export default FormPanel;
